import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import {
    AuthState,
    DataAccessModule,
    GreenhousesState,
    ProgrammingState,
    UsersState,
    UserState
} from '@hispaled/data-access';
import { File } from '@ionic-native/file/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// required for AOT compilation
export const HTTP_LOADER_FACTORY = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgxsModule.forRoot([AuthState, GreenhousesState, ProgrammingState, UsersState, UserState], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false //true by default. Trying to catch http response errors
            }
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsResetPluginModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__hispaledDb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        NoopAnimationsModule,
        DataAccessModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HTTP_LOADER_FACTORY,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        File,
        FileOpener
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
