import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as LogRocket from 'logrocket';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

LogRocket.init(`4qdjgd/${environment.logRocketId}`);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
