export const environment = {
  production: false,
  thingsBoardApiUrl: 'https://api.grow.dev.hispaled.es/api',
  growApiUrl: 'https://api.grow.dev.hispaled.es/grow/v1',
  rflightApiUrl: '',
  logRocketId: 'hispaled-grow-dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
