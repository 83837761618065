import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService, UserInfoResolver } from '@hispaled/data-access';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    resolve: {
      user: UserInfoResolver
    },
    loadChildren: () => import('./pages/nav-menu/nav-menu.module').then(m => m.NavMenuModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always' // allow all parameters to be inherited from parents so we can get id from url
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
